import React from "react"
import { FormattedMessage, useIntl } from "react-intl"
import {
  Button,
  Container,
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
} from "reactstrap"
import { config } from "../../lib/config"
import { externalLinks } from "../../lib/external-links"
import { Locales } from "../../lib/types/shared"
import { LanguageSwitch } from "../shared/language-swtich"
import { Image } from "../shared/image"

const HtgfHeader: React.FC = () => {
  const intl = useIntl()
  const locale = intl.locale as Locales
  return (
    <Navbar color="white" className="border-bottom">
      <Container>
        <NavbarBrand
          href={externalLinks[locale].htgfHome}
          rel="noopener noreferrer"
          className="mr-auto"
        >
          <Image
            width={200}
            height={59}
            alt="HTGF Careers"
            className="logo"
            src="/web-catalog-app/assets/htgf/logo-htgf.png"
          />
        </NavbarBrand>

        <Nav navbar className="flex-row align-items-center">
          <LanguageSwitch className="mr-6 text-uppercase" />
          <NavItem>
            <Button
              href={`${config.urls.candidateApp}/signup`}
              target="_blank"
              rel="noopener noreferrer"
              color="primary"
            >
              <FormattedMessage
                id="default.header.signUp"
                defaultMessage="Sign up"
              />
            </Button>
            <Button
              className="ml-2"
              href={`${config.urls.companyApp}/signup?utm_source=htgf`}
              target="_blank"
              rel="noopener noreferrer"
              color="light"
            >
              <FormattedMessage
                id="default.header.signUpAsCompany"
                defaultMessage="Signup as Company"
              />
            </Button>
          </NavItem>
        </Nav>
      </Container>
    </Navbar>
  )
}

export default HtgfHeader
